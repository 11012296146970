<template>
  <div id="report_member_pay_in_village" class="container--fluid">
    <v-card>
      <v-card-title>
        รายงานข้อมูลสรุป(หมู่บ้าน)
        <v-spacer></v-spacer>
        <v-col cols="12" md="6" lg="2">
          <v-select
            v-model="dataFrm.ProID"
            :items="this.$store.getters.getlistProvince || []"
            item-value="ProID"
            item-text="ProName"
            label="จังหวัด"
            required
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" lg="1">
          <v-select
            v-model="dataFrm.DisID"
            :items="
              this.$store.getters.getlistDistrict({
                ProID: this.dataFrm.ProID,
              }) || []
            "
            item-text="DisName"
            item-value="DisID"
            label="อำเภอ"
            required
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" lg="1">
          <v-select
            v-model="dataFrm.LocID"
            :items="
              this.$store.getters.getlistLocality({
                ProID: this.dataFrm.ProID,
                DisID: this.dataFrm.DisID,
              }) || []
            "
            item-text="LocName"
            item-value="LocID"
            label="ตำบล"
            required
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" lg="1">
          <v-select
            :items="this.$store.getters.getlistPeriod"
            label="งวดที่"
            item-text="period_number"
            item-value="period_number"
            v-model="period"
          ></v-select>
        </v-col>
        <v-btn color="success" @click="loadData"> แสดง </v-btn>
        <!-- <v-divider class="mx-4" inset vertical></v-divider>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field> -->
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn color="gray" class="ml-2" @click="onExport">Export</v-btn>
      </v-card-title>
      <v-data-table :headers="headers" :items="desserts" :search="search">
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on"
                ><v-icon>mdi-cogs</v-icon> เอกสาร
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link @click="showReport(item, 1)">
                <v-list-item-title
                  ><v-icon>mdi-pdf-box</v-icon>
                  ใบรายชื่อสมาชิกสำหรับเก็บเงิน</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`item.ssav_money_member_pay3`]="{ item }">
          {{
            $store.getters.number_format(Math.floor(item.ssav_money_member_pay * 3) / 100)
          }}
        </template>
        <template v-slot:[`item.ssav_money_member_paybd`]="{ item }">
          {{
            $store.getters.number_format(
              item.ssav_money_member_pay -
                Math.floor(item.ssav_money_member_pay * 3) / 100
            )
          }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  name: "report_member_pay_in_village",
  data() {
    return {
      dataFrm: {
        ProID: "50",
        DisID: "10",
        LocID: "01",
      },
      desserts: [],
      search: "",
      period: "",
      headers: [
        { text: "หมู่บ้าน", value: "vil_name" },
        { text: "งวดที่", value: "sav_period" },
        { text: "สมาชิกทั้งหมด", value: "count_member" },
        { text: "เรียกเก็บ", value: "ssav_money" },
        { text: "สมาชิกที่ชำระ", value: "count_member_pay" },
        { text: "ชำระ", value: "ssav_money_member_pay" },
        { text: "หัก 3%", value: "ssav_money_member_pay3" },
        { text: "ยอดรับ", value: "ssav_money_member_paybd" },
        { text: "สมาชิกไม่ได้ชำระ", value: "count_member_not_pay" },
        { text: "ค้างชำระ", value: "ssav_money_not_pay" },
        { text: "เอกสาร", value: "actions" },
      ],
    };
  },
  methods: {
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(
        this.$store.getters.getlistMemberReport
      );
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "report_member_pay_in_village.xlsx");
    },
    showReport(item, type) {
      console.log(type);
      if (type === 1) {
        window.open(
          "https://www.maeainetworkcommittee.com/report/CreatePDF/examples/main_report1.php?type_report=locality&period_number=" +
            item.sav_period +
            "&locality=" +
            item.mem_head_line,
          "_blank"
        );
      }
    },
    loadData() {
      // console.log("test");
      window.$.post(
        this.$store.state.pathAPI + "report/member_pay_villate",
        {
          period: this.period,
          ProID: this.dataFrm.ProID,
          DisID: this.dataFrm.DisID,
          LocID: this.dataFrm.LocID,
        },
        (param) => {
          this.desserts = param;
        },
        "json"
      );
    },
  },
  async mounted() {
    this.$store.dispatch("getlistPeriod");
    // await this.loadData();
  },

  filters: {
    ConvDateToDMY_TH: (dateTxt) => {
      let dtArr = dateTxt.split("-");
      return (
        dtArr[2] + "/" + dtArr[1] + "/" + (parseInt(dtArr[0]) + 543).toString()
      );
    },
  },
};
</script>

<style></style>
